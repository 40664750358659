// variables

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap");
html,
body,
label {
  font-family: "Roboto", sans-serif;
}

.noResize {
  resize: none;
}

input:focus {
  outline: none;
  border: 1px solid #abbed300;
  box-shadow: 0 0 0px #abbed300;
}

.active {
  /* Add whatever CSS style will make your link look active. The example below (in my case) */
  @apply bg-gray-700;
}

@media (min-width: 640px) {
  .sm\:ml-55 {
    margin-left: 13rem;
  }
}

.w-55 {
  width: 14rem;
}

.modal-body {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}

.PhoneInput {
  border: 1px solid #aaaeb1;
  border-radius: 6px;
  padding: 15px 15px;
  height: 42px;
  &:hover {
    border-color: rgba(0, 0, 0, 1);
  }
  &:focus {
    background: #f5f5f5;
    border-color: none;
  }
  .PhoneInputInput {
    border: none;
    background: none;
    font: inherit;
    color: currentColor;
    &:focus {
      outline: none;
    }
  }
}

#logo-sidebar {
  transition: transform 0.3s ease-in-out;
}
